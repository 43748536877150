@import '../../../../styles/customMediaQueries.css';

.megamenuseperator {
  width: 100%;
  height: 3px;
  background-color: black;
  position: relative;
  display: block;

  @media (--viewportMedium) {
    display: none !important;
  }
}

.megamenuseperator::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -20px; /* Adjust this value to position the arrow */
  border-width: 10px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  transform: translateX(-50%);
}
