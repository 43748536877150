.mega-menu {
  display: flex;

  position: relative;
  background-color: #fafafa;
  box-shadow: var(--boxShadowLight);
}

.menu-item {
  margin-right: 20px;
}

.menu-title {
  /* Basic styling */
  cursor: pointer;
  padding: 10px; /* Increase padding for better click/touch area */
  display: flex;
  align-items: center;
  font-weight: bold;
  /* border: 1px solid #ccc; Add border */
}
.menu-title.active {
  border-bottom: 3px solid var(--marketplaceColorDark);
}

.submenu {
  position: absolute;
  top: 100%;
  /* left: 0; */
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  opacity: 0;
  z-index: 1000;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  width: 30vw; /* Set width to 100% of viewport width */
  white-space: nowrap; /* Prevent items from wrapping by default */
}

.menu-item:hover .submenu {
  opacity: 1;
  visibility: visible;
}

.submenu-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  /* min-height: 450px; */
}

/* .submenu-row:first-of-type {
    border-right: 1px solid #b3b2b2;
    width: 60px;
  } */

.submenu-row {
  flex-grow: 2;
}

.submenu ul {
  list-style-type: none;
  padding: 0;
  margin-right: 0px;
}

.submenu ul li {
  padding: 5px 10px; /* Adjust padding as needed */
  cursor: pointer;
  flex: 1; /* Each item takes up 25% of the submenu width by default */
  box-sizing: border-box;
}

/* Style for hover effect */
.submenu ul li:hover {
  /* background-color: #ddd; */
  border-left: 2px solid var(--marketplaceColorDark);
  color: var(--marketplaceColorDark);
}

.submenu-row li {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  /* width: 50px; */
}

.submenu-row:first-of-type {
  width: 10px;
}

.submenu-row li.active {
  /* background-color: #ddd; */
  border-left: 2px solid var(--marketplaceColorDark);
  color: var(--marketplaceColorDark);
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .submenu {
    width: 100vw;
    left: 0;
  }
  .submenu-row {
    justify-content: center;
    flex: 0 0 50%; /* Each item takes up 50% of the width on smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .submenu {
    width: 100vw;
    left: 0;
  }
  .submenu-row {
    justify-content: center;
    flex: 0 0 100%; /* Each item takes up 100% of the width on even smaller screens */
  }
}

.brands-scroll-box {
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  width: 100%;
}

.brands-scroll-box-inner {
  overflow-y: scroll;
  max-height: 300px;
}

.brands-scroll-box-inner a {
  color: black;
  text-decoration: none;
}
